@use '@angular/material' as mat;

// Tailwind directives
@tailwind base;
@tailwind components;
@tailwind utilities;

// Include the common styles for Angular Material
@include mat.core();

// Custom styles
:root {
  //colors
  --ayr-black: #000000;
  --ayr-black-opacity-90: rgba(0, 0, 0, 0.9);
  --ayr-white: #ffffff;
  --ayr-green-200: #2effa8;
  --ayr-green-500: #0bcf9e;
  --ayr-green-800: #089370;
  --ayr-green-900: #068666;
  --ayr-green-1000: #023327;
  --ayr-yellow-200: #f2f2df;
  --ayr-yellow-200-opacity-90: #f2f2df90;
  --ayr-grey-700-opacity-90: rgba(51, 51, 51, 0.9);
  --ayr-grey-700: rgba(51, 51, 51);
  --ayr-grey-900: #242424;
  --ayr-grey-1000: #1a1a1a;

  --text-h1: 2.875rem;
  --text-h2: 2.25rem;
  --text-h3: 1.375rem;
  --text-h4: 1.25rem;
  --text-normal: 1rem;
  --text-caption: 0.8125rem;
  --text-small-caption: 0.6875rem;

  --text-47: 2.9375rem;
  --text-37: 2.3125rem;
  --text-27: 1.6875rem;
  --text-23: 1.4375rem;
  --text-21: 1.3125rem;
  --text-18: 1.125rem;
  --text-15: 0.9375rem;
  --text-13: 0.8125rem;
  --text-11: 0.6875rem;
  --text-10: 0.6rem;

  --max-width-960: 960px;
}

$green: #0bcf9e;
$highlight: #2effa8;
$beige: #f2f2df;
$black: rgb(51, 51, 51);
$dark-black: #000000;
$white: #ffffff;

html,
body {
  height: 100%;
  overscroll-behavior-y: none;
}

html {
  background-color: $white;
}

html.dark {
  background-color: $dark-black;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.text-bold, b {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

p:empty, span:empty {
  height: 0;
  padding: 0;
  margin: 0;
}

.text-semibold {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: clamp(0.8rem, 1.25vw, 1.125rem) !important;
  letter-spacing: 0;
  color: rgb(51, 51, 51);
}

.dark text-shadow {
  text-shadow: #2a2a2acc 1px 0 1rem;
}

.text-shadow-white-text {
  text-shadow: #2a2a2acc  1px 0 1rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgb(133, 133, 133, 0.4156862745);
}

:hover::-webkit-scrollbar-thumb {
  background: #858585;
  border-radius: 6px;
}

.green-highlight,
.dark-highlight {
  position: relative;
  z-index: 2;
}

.dark-highlight::after {
  content: attr(data-content);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 34%;
  background-color: $black;
  z-index: -1;
}

.navigation-item-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.green-highlight::after {
  content: attr(data-content);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 34%;
  background-color: $highlight;
  z-index: -1;
}

.ayr-underline,
.ayr-underline-grey {
  position: relative;
  z-index: 1;

  &:after {
    position: absolute;
    content: "";
    height: 34%;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--ayr-green-200);
    z-index: -1;
  }
}


/*.dark .ayr-underline:after, .dark .green-highlight::after {
  background-color: var(--ayr-green-500);
}*/

.owl-carousel .owl-item {
  width: 100%;
}

.owl-carousel .owl-stage-outer {
  transform: translate3d(0, 0, 0)
}

app-image-slider #secondary .owl-stage .owl-item {
  display: flex;
  justify-content: center;
}

@media (max-width: 320px) {
  body {
    min-width: 320px;
  }
}

// Layer directive to ensure Tailwind utilities take precedence
@layer utilities {
  .text-shadow {
    text-shadow: #2a2a2acc 1px 0 1rem;
  }

  .text-shadow-white-text {
    text-shadow: #2a2a2acc 1px 0 1rem;
  }
}

app-navbar {
  position: sticky;
  top: 0;
  z-index: 100;
}

